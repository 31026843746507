import React, { useEffect, useState } from 'react';
import {
  Container,
  CssBaseline,
  Box,
  Grid,
  Button,
  Grow
} from '@mui/material'
import { useGlobalState } from '../gloabl';
import {revokeCertificate, unrevokeCertificate} from "./support/ApiService";
import Typography from "@mui/material/Typography";
import {DeviceOrHostChooser, isDevice} from "./DeviceOrHostChooser";
export default function RevokeUnrevokeCertificates() {
  const [state, dispatch] = useGlobalState();
  const [deviceOrHost, setDeviceOrHost] = useState("");
  const [deviceId, setdeviceId] = useState("");
  const [hostDomain, setHostDomain] = useState("");
  const [result, setResult] = useState(undefined);

  useEffect(() =>{
    setResult(undefined);
  },[deviceId, hostDomain, deviceOrHost]);
  const revokeClicked = () => {
    setResult(undefined);

    if (isDevice(deviceOrHost) ? !deviceId : !hostDomain) {
      dispatch({"error":"All fields must be filled"})
      return true;
    }

    let confirmDlgConfig = {
      confirmationQuestion: `Caution! ALL certificates and keys of the ${deviceOrHost} will be revoked. Are you sure you really want this? `,
      confirmButtonLabel: "Revoke",
      cancelAction: () => {console.log("revoke cancel", deviceOrHost, deviceId, hostDomain)},
      okAction: doRevoke
    }
    dispatch({"confirmDlgConfig": confirmDlgConfig});
  }

  const unrevokeClicked = () => {
    setResult(undefined);
    if (isDevice(deviceOrHost) ? !deviceId : !hostDomain) {
      dispatch({"error":"All fields must be filled"})
      return true;
    }
    let confirmDlgConfig = {
      confirmationQuestion: `Caution! The revocation for ALL certificates and keys of the ${deviceOrHost} will be undone. Are you sure you really want this? `,
      confirmButtonLabel: "Undo revocation",
      cancelAction: () => {console.log("unrevoke cancel", deviceOrHost, deviceId, hostDomain); },
      okAction: doUnrevoke
    }
    dispatch({"confirmDlgConfig":confirmDlgConfig});
  }

  const doRevoke = () => {

    dispatch({"message": "Communicating ...."});
    revokeCertificate(deviceOrHost, isDevice(deviceOrHost) ? deviceId: hostDomain).
        then((r)=> {
      dispatch({"message":null});
          setResult(r);
        }).catch( e => {
      dispatch({"message":null});
      let error = e.response;
      error.ErrorDescription = "Error revoking";
      dispatch({"error": error});
    });
  }

  const doUnrevoke = () => {
    dispatch({"message": "Communicating ...."});
    unrevokeCertificate(deviceOrHost, isDevice(deviceOrHost)? deviceId: hostDomain).
    then((r)=> {
      dispatch({"message":null});
      setResult(r);
    }).catch( e => {
      dispatch({"message":null});
      let error = e.response;
      error.ErrorDescription = "Error undo revocation";
      dispatch({"error": error});
    });
  }

  const createResultDescription = () => {
    let countCertificates = 0;
    let countKeys = 0;
    let resultDescription ="";
    if (result && result.revocations && Array.isArray(result.revocations))
    {
      result.revocations.forEach(revokeEl=> {
       if (revokeEl.publicKey)
         countKeys++;
       if (revokeEl.certificate)
         countCertificates ++;
      });
      resultDescription = `${countCertificates} certificate(s) and ${countKeys} key(s) have been revoked for ${deviceOrHost} "${isDevice(deviceOrHost) ? deviceId: hostDomain}"`;
    }
    else if (result && result.undonerevocations && Array.isArray(result.undonerevocations))
    {
      result.undonerevocations.forEach(revokeEl=> {
        if (revokeEl.publicKey)
          countKeys++;
        if (revokeEl.certificate)
          countCertificates ++;
      });

      resultDescription = `The revocation of ${countCertificates} certificate(s) and ${countKeys} key(s) is undone for ${deviceOrHost} "${isDevice(deviceOrHost) ? deviceId: hostDomain}"`;
    }

    if (result)
    {
      return <Typography>{resultDescription}</Typography>
    }
  }

  return (
    <>
    <h3>Revoke/Undo revocation of certificates and keys</h3>
    <CssBaseline />
      <Container maxWidth="lg">
       <Grow  in={true} timeout={400}>
       <Box sx={{ flexGrow: 1 , marginTop: "10px"}}>
         <Grid container spacing={2} style={{textAlign:"left"}}>

           <DeviceOrHostChooser hostDomain={hostDomain} deviceId={deviceId}
                                deviceOrHostValue={deviceOrHost} onChangeHostDomain={value=>setHostDomain(value)}
                                onChangeDeviceId={value=>setdeviceId(value)}
                                onChangeDeviceOrHost={value=>setDeviceOrHost(value)}/>


           {deviceOrHost &&
             <>
               <Grid item xs={3}></Grid>
               <Grid item xs={9}>
                 <Button variant="contained" onClick={() => revokeClicked()} >
                  Revoke
                 </Button>

                 <Button variant="contained"  style={{ marginLeft: "10px"}} onClick={() => unrevokeClicked()} >
                   Undo revocation
                 </Button>
                 {createResultDescription()}
               </Grid>
             </>
         }

        </Grid>
     </Box>
     </Grow>
      </Container>
    </>
  );
}

