import {API, Auth} from "aws-amplify";

async function postMethod(path, data) {
    const myInit = {
        headers: { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: data,
    };
    return await API.post("pki", path, myInit);
}

async function deleteMethod(path, data) {
    const myInit = {
        headers: { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: data,
    };
    return await API.del("pki", path, myInit);
}

async function getMethod(path) {
    const myInit = {
        headers: { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
    };
    return await API.get("pki", path, myInit);
}

export async function unrevokeCertificate(type, principal) {
    const path=`certificates/type/${type}/revocations`;
    const data = {
        principal: principal,
    };

    return deleteMethod(path, data);
}

export async function getReport(type, principal) {
    const path = `/certificates/type/${type}/principal/${principal}`;
    return getMethod(path);
}

export async function revokeCertificate(type, principal) {
    const path=`certificates/type/${type}/revocations`;
    const data = {
        principal: principal,
    };

    return postMethod(path, data);
}

export async function requestCertificate(type, publicKey, principal, signature) {
    const path=`certificates/type/${type}`;
    const data = {
        publicKey: btoa(publicKey),
        principal: principal,
        signature:  btoa(signature),
    };

    return postMethod(path, data);
}

export async function requestRenewCertificate(publicKey, certificate, signatureOldKey, signatureNewKey ) {
    const path='certificates/renew';
    const data = {
        publicKey: btoa(publicKey),
        certificate: btoa(certificate),
        signatureOld: btoa(signatureOldKey),
        signatureNew: btoa(signatureNewKey),
    };

    return postMethod(path, data);
}
